import { createRouter, createWebHashHistory } from 'vue-router';

const routes = [
    {
        path: '/login',
        name: 'login',
        component: () => import('./views/Login.vue'),
        meta: {
            requiresAuth: false,
            freeLayout: true
        }
    },
    {
        path: '/',
        name: 'dashboard',
        component: () => import('./views/Dashboard.vue'),
        meta: {
            requiresAuth: true,
            freeLayout: false
        }
    },
    {
        path: '/perfil',
        name: 'perfil',
        component: () => import('./views/Perfil.vue'),
        meta: {
            requiresAuth: true,
            freeLayout: false
        }
    },
    {
        path: '/lojas',
        name: 'lojas',
        component: () => import('./views/Usuarios.vue'),
        meta: {
            requiresAuth: true,
            freeLayout: false
        }
    },
    {
        path: '/autorizacoes',
        name: 'autorizacoes',
        component: () => import('./views/Autorizacoes.vue'),
        meta: {
            requiresAuth: true,
            freeLayout: false
        }
    },
    {
        path: '/ceps',
        name: 'ceps',
        component: () => import('./views/Ceps.vue'),
        meta: {
            requiresAuth: true,
            freeLayout: false
        }
    },
    {
        path: '/financeiro',
        name: 'financeiro',
        component: () => import('./views/Financeiro.vue'),
        meta: {
            requiresAuth: true,
            freeLayout: false
        }
    },

];

const router = createRouter({
    history: createWebHashHistory(),
    routes,
});

export default router;
