import axios from 'axios';

import firebase from 'firebase/app';
import 'firebase/auth';

export default class {

    constructor(api) {
        this.urlApi = api;
    }

    async setBearerToken(token) {
        this.token = token;
    }

    async headers() {

        let token;
        if(typeof this.token == 'string') {
            token = this.token;
            this.token = null;
        } else {
            token = await firebase.auth().currentUser.getIdToken();
        }

        return { 
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + token,
        };
        
    }

    async get(endpoint/*, data = null*/) {

        if(typeof endpoint != "string")
            return null;

        //TRATAR PARAMS typeof data != object || data == null

        return axios({
            method: 'get',
            url: this.urlApi + endpoint,
            headers: await this.headers(),
        });

    }

    async post(endpoint, data = null) {

        if(typeof endpoint != "string" || typeof data != "object" || data == null)
            return null;

        return axios({
            method: 'post',
            url: this.urlApi + endpoint,
            headers: await this.headers(),
            "data" : data
        });

    }

}