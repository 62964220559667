<template>
	<div v-if="withLayout" :class="containerClass" @click="onWrapperClick">
		<AppTopBar @menu-toggle="onMenuToggle" />

        <transition name="layout-sidebar">
            <div :class="sidebarClass" @click="onSidebarClick" v-show="isSidebarVisible()">
                <div class="layout-logo">
                    <router-link to="/">
                        <img v-if="layoutColorMode === 'dark'" alt="Logo" src="./assets/logo.png" />
                        <img v-else alt="Logo" src="./assets/logo.png" />
                    </router-link>
                </div>

                <AppProfile />

                <AppMenu :model="menu" @menuitem-click="onMenuItemClick" />

            </div>
        </transition>

		<div class="layout-main">
			<router-view />
		</div>

		<AppFooter />
	</div>
    <div v-else><router-view /></div>
</template>

<script>
import AppTopBar from './components/AppTopbar.vue';
import AppProfile from './components/AppProfile.vue';
import AppMenu from './components/AppMenu.vue';
import AppFooter from './components/AppFooter.vue';

import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';

export default {
    data() {
        return {
            withLayout: false,
            layoutMode: 'static',
            layoutColorMode: 'dark',
            staticMenuInactive: false,
            overlayMenuActive: false,
            mobileMenuActive: false, 
            version: "2.3.6 beta",
            user: null,
            logisticaAtual: null,
            logisticas: [],
            menu : [
                {label: 'Dashboard', icon: 'pi pi-fw pi-th-large', to: '/'},
                {label: 'CEPS', icon: 'pi pi-fw pi-map-marker', to: '/ceps', badge: null}
            ],
            menu_ceps_i: 1
        }
    },
    created() {

        const self = this;

        firebase.auth().onAuthStateChanged((user) => {
            if(user) {
                firebase.database().ref("Usuarios").child(user.uid).on("value", (snapshot) => {
                    if(snapshot.exists()) {
                        let u = snapshot.val();
                        delete u.senha;
                        delete u.senhaRelatorios;

                        if(typeof u.empresaLogistica != "undefined") {
                            delete u.empresaLogistica.senha;
                            if(typeof u.empresaLogistica.senhaRelatorios != "undefined")
                                delete u.empresaLogistica.senhaRelatorios;

                            self.logisticaAtual = {
                                nome : u.empresaLogistica.nome,
                                id: u.empresaLogistica.id
                            };
                        } else if(typeof u.permicao != "undefined" && u.permicao == "EmpresaLogistica") {
                            self.logisticaAtual = {
                                nome : u.nome,
                                id: u.id
                            };
                        }

                        if(typeof u.permicao == "undefined")
                            firebase.auth().signOut();
                        else if(u.permicao == "Admin") {
                            self.loadLogisticas();
                        } else if(u.permicao == "Gerente") {
                            if(typeof u.empresasLogisticasPermitidas == "undefined")
                                self.logisticas = [self.logisticaAtual];
                            else {
                                let logisticas = [];
                                for(const l of Object.values(u.empresasLogisticasPermitidas)) {
                                    logisticas.push({
                                        nome: l.nome,
                                        id: l.id
                                    });
                                }
                                self.logisticas = logisticas;
                            }
                        } else if(["EmpresaLogistica","Suporte"].indexOf(u.permicao) >= 0) {
                            self.logisticas = [self.logisticaAtual];
                        } else {
                            firebase.auth().signOut();
                        }

                        self.user = u;

                    } else {
                        self.user = null;
                    }
                });
            } else {
                firebase.auth().signOut();
                self.user = null;
                self.$router.push('/login');
            }
        });

        firebase.database().ref("GrerenciarVesao").child("webAdmin").on("value", (snapshot) => {
            if(!snapshot.exists()) return;
            const version = snapshot.val();
            
            if(window.location.hostname != "localhost" && version != self.version)
                location.reload(true);
        });

        firebase.database().ref("CEPSDSMInvalidos").on("value", function (snapshot) {

            if (snapshot.exists()) {
                
                const qtd = Object.values(snapshot.val()).length;
                if(qtd > 0) {
                    self.menu[self.menu_ceps_i].badge = qtd;
                } else {
                    self.menu[self.menu_ceps_i].badge = null;
                }

            } else {
                self.menu[self.menu_ceps_i].badge = null;
            }

        });


    },
    watch: {
        $route() {
            this.withLayout = !(this.$route.matched.some(record => record.meta.freeLayout));
            this.menuActive = false;
            this.$toast.removeAllGroups();
        },
        user: function(u) {
            if(u != null) {
                
                switch(u.permicao) {
                    case "Suporte" : {
                        break;
                    }
                    case "Admin":
                    case "EmpresaLogistica": {

                        if(this.menu.findIndex((e) => e.to == '/financeiro') == -1) {
                            this.menu.push({label: 'Financeiro', icon: 'pi pi-fw pi-money-bill', to: '/financeiro'});
                        }

                    }
                    case "Gerente" : {

                        if(u.id == "uDYPYvKdDPPSEj16uny6BCkuare2" && this.menu.findIndex((e) => e.to == '/financeiro') == -1) { // POG
                            this.menu.push({label: 'Financeiro', icon: 'pi pi-fw pi-money-bill', to: '/financeiro'});
                        }

                        if(this.menu.findIndex((e) => e.to == '/lojas') == -1) {
                            this.menu.push({label: 'Lojas', icon: 'pi pi-fw pi-home', to: '/lojas'});
                        }

                        if(this.menu.findIndex((e) => e.to == '/autorizacoes') == -1) {
                            this.menu.push({label: 'Autorizadores (beta)', icon: 'pi pi-fw pi-lock-open', to: '/autorizacoes'});
                        }

                    }
                }
            }
        }
    },
    methods: {
        loadLogisticas() {
            const self = this;
            let query = firebase.database().ref("Usuarios").orderByChild("permicao").equalTo("EmpresaLogistica");
            query.once('value', function (snapshot) {
                if(snapshot.exists()) {
                    self.logisticas = [];
                    for (const u of Object.values(snapshot.val())) {
                        if(u.statusEmpresa == "Ativo") {
                            self.logisticas.push({
                                nome : u.nome,
                                id: u.id
                            });
                        }
                    }
                    self.logisticas.sort((a,b) => (a.nome > b.nome) ? 1 : ((b.nome > a.nome) ? -1 : 0));
                }
            });
        },
        onWrapperClick() {
            if (!this.menuClick) {
                this.overlayMenuActive = false;
                this.mobileMenuActive = false;
            }

            this.menuClick = false;
        },
        onMenuToggle() {
            this.menuClick = true;

            if (this.isDesktop()) {
                if (this.layoutMode === 'overlay') {
					if(this.mobileMenuActive === true) {
						this.overlayMenuActive = true;
					}

                    this.overlayMenuActive = !this.overlayMenuActive;
					this.mobileMenuActive = false;
                }
                else if (this.layoutMode === 'static') {
                    this.staticMenuInactive = !this.staticMenuInactive;
                }
            }
            else {
                this.mobileMenuActive = !this.mobileMenuActive;
            }

            event.preventDefault();
        },
        onSidebarClick() {
            this.menuClick = true;
        },
        onMenuItemClick(event) {
            if (event.item && !event.item.items) {
                this.overlayMenuActive = false;
                this.mobileMenuActive = false;
            }
        },
		onLayoutChange(layoutMode) {
			this.layoutMode = layoutMode;
		},
		onLayoutColorChange(layoutColorMode) {
			this.layoutColorMode = layoutColorMode;
		},
        addClass(element, className) {
            if (element.classList)
                element.classList.add(className);
            else
                element.className += ' ' + className;
        },
        removeClass(element, className) {
            if (element.classList)
                element.classList.remove(className);
            else
                element.className = element.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
        },
        isDesktop() {
            return window.innerWidth > 1024;
        },
        isSidebarVisible() {
            if (this.isDesktop()) {
                if (this.layoutMode === 'static')
                    return !this.staticMenuInactive;
                else if (this.layoutMode === 'overlay')
                    return this.overlayMenuActive;
                else
                    return true;
            }
            else {
                return true;
            }
        },
    },
    computed: {
        containerClass() {
            return ['layout-wrapper', {
                'layout-overlay': this.layoutMode === 'overlay',
                'layout-static': this.layoutMode === 'static',
                'layout-static-sidebar-inactive': this.staticMenuInactive && this.layoutMode === 'static',
                'layout-overlay-sidebar-active': this.overlayMenuActive && this.layoutMode === 'overlay',
                'layout-mobile-sidebar-active': this.mobileMenuActive,
				'p-input-filled': 'outlined', // opção 2 // 'filled',
				'p-ripple-disabled': false,
            }];
        },
        sidebarClass() {
            return ['layout-sidebar', {
                'layout-sidebar-dark': this.layoutColorMode === 'dark',
                'layout-sidebar-light': this.layoutColorMode === 'light'
            }];
        }
    },
    beforeUpdate() {
        if (this.mobileMenuActive)
            this.addClass(document.body, 'body-overflow-hidden');
        else
            this.removeClass(document.body, 'body-overflow-hidden');
    },
    components: {
        'AppTopBar': AppTopBar,
        'AppProfile': AppProfile,
        'AppMenu': AppMenu,
        'AppFooter': AppFooter,
    }
}
</script>

<style lang="scss">

  .layout-sidebar .layout-logo img {
      width: 139px;
      max-width: 90%;
      margin-bottom: 1em;
  }

  .p-toast.p-toast-topright {
      z-index: 1000;
      top: 70px;
  }

  .col-actions {
      width: 120px;
      text-align: center !important;
  }

  .p-autocomplete-multiple .p-inputtext {
      border-color: #0086eb; 
  }


</style>
