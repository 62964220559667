<template>
	<div class="layout-profile">
		<button class="p-link layout-profile-link" @click="onClick">
			<span class="username">{{ nome }}</span>
			<i class="pi pi-fw pi-cog"></i>
		</button>
		
        <transition name="layout-submenu-wrapper">
            <ul v-show="expanded">
                <li><button class="p-link" @click="perfil"><i class="pi pi-fw pi-user"></i><span>Perfil</span></button></li>
                <li><button class="p-link" @click="logout"><i class="pi pi-fw pi-power-off"></i><span>Sair</span></button></li>
            </ul>
        </transition>

		<div>
			<Dropdown style="margin-top: 0.5rem" v-model="logisticaAtual" :options="logisticas" optionLabel="nome" optionValue="id" placeholder="Empresa de Logística" />
		</div>
		
	</div>
</template>

<script>
	import firebase from 'firebase/app';
	import 'firebase/auth';

	export default {
		data() {
			return {
				expanded: false,
				nome: "",
				logisticaAtual: null,
				logisticas: []
			}
		},
		watch: {
			'$root.user': {
				handler: function(user) {
					if(user) {
						this.nome = user.nome;
					} else {
						this.nome = "";
					}
				},
				deep: true,
				immediate: true
			},
			'$root.logisticas': {
				handler: function(val) {
					if(val) {
						this.logisticas = val;
					} else {
						this.logisticas = [];
					}
				},
				deep: true,
				immediate: true
			},
			'$root.logisticaAtual': {
				handler: function(val) {
					if(val) {
						this.logisticaAtual = val.id;
					} else {
						this.logisticaAtual = null;
					}
				},
				deep: true,
				immediate: true
			},
			logisticaAtual: function(val) {
				for(const k in this.logisticas) {
					if(this.logisticas[k].id == val) {
						this.$root.logisticaAtual = this.logisticas[k];
						break;
					}
				}
			}

		},
		methods: {
			onClick(event){
				this.expanded = !this.expanded;
				event.preventDefault();
			},
			logout() {
				firebase.auth().signOut();
			},
			perfil() {
				const self = this;
				self.$router.push('/perfil');
			}
		}
	}
</script>

<style scoped>

</style>